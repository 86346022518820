import React, { useState } from 'react';

export default (WrapperComponent) => {
  const HeaderContainer = (props) => {
    const [activeTool, setActiveTool] = useState('');

    let timer = null;

    const toggle = (id) => {
      setActiveTool((prevState) => {
        const doToggleOff = id === prevState;
        // side effect start
        if (doToggleOff) {
          document.body.classList.remove('header-panel-is-open');
        } else {
          document.body.classList.add('header-panel-is-open');
        }
        // side effect end
        return doToggleOff ? '' : id;
      });

      if (id === 'search') {
        const mq = window.matchMedia('(min-width: 1025px)');
        mq.matches && setFocus('searchfield_search');
      }
      if (id === 'reading-aid') {
        setFocus('reading-aid');
      }
    };

    const setFocus = (id) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        document.getElementById(id).focus();
      }, 420);
    };

    return (
      <WrapperComponent
        {...props}
        {...activeTool}
        toggle={(e) => toggle(e)}
        activeTool={activeTool}
      />
    );
  };
  return HeaderContainer;
};
