import React from 'react';
import { SearchTool } from '../header-tools/tools';

const Tool = (props) => {
  return (
    <>
      <div className="tool">{props.SiteTool}</div>
      <ul className="c-mobile-headertool">
        {<SearchTool {...props} isMobile={true} />}
      </ul>
    </>
  );
};

export default Tool;
