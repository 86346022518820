import React, { useEffect } from 'react';
import * as Render from '../../platformui/Base/Render';
import Header from '../../components/header';
import CookiePanel from '../../platformui/CookiePanel';
import ScrollToTop from '../../platformui/ScrollToTop';
import Footer from '../../components/footer';
import SurveyEntry from '../../platformui/SurveyEntry';
import AccessibilityNav from '../../platformui/AccessibilityNav';

const AccessibilityShortcuts = (props) => {
  return (
    <AccessibilityNav>
      <li>
        <a href="#content">{props.Content.Translations.maincontentnav}</a>
      </li>
      {props.PageTypeName === 'SearchPage' && (
        <li>
          <a href="#search">{props.Content.Translations.searchresultnav}</a>
        </li>
      )}
    </AccessibilityNav>
  );
};

const Layout = (props) => {
  useEffect(() => {
    if (props.BuildInfo) {
      document.getElementsByTagName('body')[0].classList.add('has-buildinfo');
    }
  }, []);

  const activeSurvey = props.SurveyEntries?.find((survey) => survey.IsActive);

  return (
    <div>
      <AccessibilityShortcuts {...props} />
      {props.BuildInfo && <props.BuildInfoComponent {...props} />}
      <Header {...props} />
      <div className="main-wrapper">
        {activeSurvey && <SurveyEntry {...activeSurvey} />}
        {<props.PageTypeRenderer key="asyncpages" {...props} />}
        <ScrollToTop key="scrolltop" {...props} />
      </div>
      <Footer {...props} />
      {!props.CookiePro.Enabled && <CookiePanel {...props} />}
    </div>
  );
};

export default Render.Root(Layout);
