import React from 'react';
import classNames from 'classnames';
import SearchField from '../../platformui/Search/searchfield';

const GlobalSearch = (props) => {
  const globalSearchClasses = classNames({
    'c-globalsearch': true,
    'c-globalsearch--is-opened': props.activeTool === 'search',
    'c-globalsearch--is-mobile': props.isMobile,
    'c-globalsearch--is-desktop': !props.isMobile,
    ...props.modifiers,
  });
  return (
    <div
      className={globalSearchClasses}
      id={props.id}
      data-cy={props.isMobile ? null : 'globalsearch-panel'}
      aria-hidden={props.activeTool !== 'search'}
    >
      <div className="c-globalsearch__inner" role="search">
        <form
          action={props.SiteHeader.Search.Link}
          method="get"
          autoComplete="off"
        >
          <div className="c-globalsearch__form-component">
            <label
              className="c-globalsearch__field__label icon-search"
              htmlFor={`searchfield_${props.id}`}
            >
              <span className="show-for-sr">
                {props.SiteHeader.Search.PlaceholderText &&
                  props.SiteHeader.Search.PlaceholderText}
              </span>
            </label>

            <SearchField
              id={`searchfield_${props.id}`}
              className="c-globalsearch"
              searchUrl={props.SiteHeader.Search.Link}
              placeholder={props.SiteHeader.Search.PlaceholderText}
            />

            <button type="submit" className="c-globalsearch__field__button">
              {props.SiteHeader.Search.Label}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GlobalSearch;
