import React from 'react';
import LogoSvg from './logoSvg';

const Logo = (props) => {
  return (
    <div className="logo">
      <div className="logo__inner">
        <a href="/" className="logo__link">
          <span className="show-for-sr" data-cy="ui-header-logosr">
            {props.logosr}
          </span>
          <LogoSvg />
        </a>
      </div>
    </div>
  );
};

export default Logo;
