const StartPage = async () =>
  await import(/* webpackChunkName: "start" */ './start').then(
    (page) => page.default
  );

const SectionStartPage = async () =>
  await import(/* webpackChunkName: "sectionstart" */ './sectionstart').then(
    (page) => page.default
  );
const CollectionPage = async () =>
  await import(/* webpackChunkName: "collection" */ './collection').then(
    (page) => page.default
  );
const ArticlePage = async () =>
  await import(/* webpackChunkName: "article" */ './article').then(
    (page) => page.default
  );
const StatisticsPage = async () =>
  await import(/* webpackChunkName: "statistics" */ './statistics').then(
    (page) => page.default
  );
const ManualPage = async () =>
  await import(/* webpackChunkName: "manual" */ './manual').then(
    (page) => page.default
  );
const SearchPage = async () =>
  await import(/* webpackChunkName: "search" */ './search').then(
    (page) => page.default
  );
//const LinkCollectionPage = async () => await import(/* webpackChunkName: "linkcollectionpage" */ './linkcollectionpage').then(page => page.default);
const ErrorPage = async () =>
  await import('./error').then((page) => page.default);

const MessagePage = async () =>
  await import(/* webpackChunkName: "message" */ './message').then(
    (page) => page.default
  );

export const importPage = (pageTypeName) => {
  switch (pageTypeName) {
    case 'StartPage':
      return StartPage();
    case 'SectionStartPage':
      return SectionStartPage();
    case 'CollectionPage':
      return CollectionPage();
    case 'ArticlePage':
      return ArticlePage();
    case 'StatisticsPage':
      return StatisticsPage();
    case 'ManualPage':
      return ManualPage();
    case 'SearchPage':
      return SearchPage();
    // case 'LinkCollectionPage': return LinkCollectionPage();
    case 'ErrorPage':
      return ErrorPage();
    case 'MessagePage':
      return MessagePage();

    default:
      return StartPage();
  }
};
