import React from 'react';
import SearchIcon from '../icons/search-icon';
import SoundIcon from '../icons/sound-icon';
import DisplayIcon from '../icons/display-icon';

export const SearchTool = (props) => {
  return (
    <li className="header-tools__list__item header-tools__list__item--search">
      <button
        type="button"
        className="header-tools__list__item__button header-tools__icon-button"
        data-cy={props.isMobile ? null : 'header-tools-search-btn'}
        onClick={() => props.toggle('search')}
        aria-expanded={props.activeTool === 'search'}
        aria-label={props.SiteHeader.Search.Label}
      >
        <SearchIcon className="svg-header-icon-path" />
        <span>{props.SiteHeader.Search.Label}</span>
      </button>
    </li>
  );
};
export const ReadingAidTool = (props) => {
  return (
    <li className="header-tools__list__item header-tools__list__item--listen">
      <button
        type="button"
        id="reading-aid-panel-btn"
        className="header-tools__list__item__button header-tools__icon-button"
        onClick={() => props.toggle('reading-aid')}
        aria-controls="reading-aid"
        aria-expanded={props.activeTool === 'reading-aid'}
        data-cy="inera-reading-aid-tool"
      >
        <SoundIcon className="svg-header-icon-path" />
        <span>{props.SiteHeader.ReadingAid.Label}</span>
      </button>
    </li>
  );
};

export const ServiceInfoTool = (props) => {
  return (
    <li className="header-tools__list__item header-tools__list__item--status">
      <a
        href={props.SiteHeader.ServiceInfo.Link}
        className="header-tools__list__item__button header-tools__icon-button"
        data-cy="inera-status-tool"
      >
        <DisplayIcon className="svg-header-icon-path" />
        <span>{props.SiteHeader.ServiceInfo.Label}</span>
      </a>
    </li>
  );
};
