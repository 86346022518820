import React from 'react';

export default function SearchIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1616 21.5526L29.5015 27.8925C29.9147 28.3057 29.8895 29.0009 29.4451 29.4452C29.0007 29.8896 28.3055 29.9148 27.8923 29.5016L21.5525 23.1618"
        stroke="#A33662"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <circle
        cx="13.75"
        cy="13.75"
        r="12"
        stroke="#A33662"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 22.75C18.7205 22.75 22.75 18.7205 22.75 13.75C22.75 8.77942 18.7205 4.75 13.75 4.75C8.77942 4.75 4.75 8.77942 4.75 13.75C4.75 18.7205 8.77942 22.75 13.75 22.75ZM13.75 17.0887C15.5939 17.0887 17.0887 15.5939 17.0887 13.75C17.0887 11.9061 15.5939 10.4113 13.75 10.4113C11.9061 10.4113 10.4113 11.9061 10.4113 13.75C10.4113 15.5939 11.9061 17.0887 13.75 17.0887Z"
        fill="#E3ABC1"
      />
    </svg>
  );
}
