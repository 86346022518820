import React from 'react';

export default function LogoSvg() {
  return (
    <svg
      className="logo__svg"
      width="885"
      height="300"
      viewBox="0 0 885 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.3409 132.364C71.2513 132.364 58.4557 128.483 47.5721 121.211C36.6885 113.938 28.2058 103.602 23.1966 91.5089C18.1874 79.4157 16.8768 66.1087 19.4304 53.2707C21.9841 40.4326 28.2873 28.6401 37.5431 19.3843C46.7988 10.1286 58.5913 3.82535 71.4294 1.2717C84.2675 -1.28195 97.5745 0.0286757 109.668 5.03784C121.761 10.047 132.097 18.5297 139.369 29.4133C146.641 40.2969 150.523 53.0926 150.523 66.1822C150.498 83.7272 143.518 100.546 131.111 112.953C118.705 125.359 101.886 132.34 84.3409 132.364ZM84.3409 41.0367C79.3676 41.0367 74.506 42.5114 70.3708 45.2745C66.2357 48.0375 63.0127 51.9647 61.1095 56.5594C59.2063 61.1541 58.7084 66.21 59.6786 71.0878C60.6488 75.9655 63.0437 80.446 66.5604 83.9627C70.077 87.4793 74.5575 89.8742 79.4352 90.8445C84.313 91.8147 89.3689 91.3167 93.9636 89.4135C98.5584 87.5103 102.486 84.2874 105.249 80.1522C108.012 76.0171 109.486 71.1555 109.486 66.1822C109.478 59.5157 106.826 53.1246 102.112 48.4107C97.3984 43.6968 91.0073 41.0449 84.3409 41.0367Z"
            fill="#A33662"
          />
          <path
            d="M167.847 234.036C167.847 211.897 159.053 190.665 143.398 175.01C127.743 159.355 106.511 150.561 84.372 150.561C62.233 150.561 41.0007 159.355 25.346 175.01C9.69128 190.665 0.896571 211.897 0.896571 234.036V295.544C0.893616 295.813 0.966337 296.077 1.10643 296.307C1.24652 296.536 1.44833 296.722 1.68881 296.842C1.92928 296.962 2.19873 297.013 2.46637 296.987C2.73401 296.961 2.98905 296.861 3.20235 296.697L41.3724 268.311C41.5472 268.174 41.6885 267.999 41.7857 267.799C41.8828 267.6 41.9333 267.38 41.9332 267.158V234.036C42.2328 222.984 46.8338 212.485 54.757 204.773C62.6802 197.062 73.3001 192.747 84.3565 192.747C95.4128 192.747 106.033 197.062 113.956 204.773C121.879 212.485 126.48 222.984 126.78 234.036V295.544C126.78 295.933 126.934 296.305 127.209 296.58C127.483 296.855 127.856 297.009 128.244 297.009H166.383C166.771 297.009 167.144 296.855 167.419 296.58C167.693 296.305 167.847 295.933 167.847 295.544V234.036Z"
            fill="#A33662"
          />
          <path
            d="M295.039 110.491C295.101 115.434 293.692 120.283 290.991 124.423C288.289 128.563 284.418 131.807 279.869 133.742C275.32 135.676 270.298 136.214 265.443 135.288C260.587 134.362 256.116 132.012 252.599 128.539C249.081 125.066 246.676 120.625 245.689 115.781C244.701 110.937 245.177 105.909 247.054 101.336C248.931 96.7633 252.125 92.8516 256.231 90.0985C260.337 87.3455 265.169 85.8753 270.112 85.8749C276.657 85.9144 282.928 88.5109 287.585 93.1099C292.242 97.709 294.917 103.946 295.039 110.491Z"
            fill="#A33662"
          />
          <path
            d="M289.244 154.799H251.292C250.5 154.799 249.858 155.441 249.858 156.233V295.545C249.858 296.337 250.5 296.979 251.292 296.979H289.244C290.035 296.979 290.677 296.337 290.677 295.545V156.233C290.677 155.441 290.035 154.799 289.244 154.799Z"
            fill="#A33662"
          />
          <path
            d="M455.758 216.494V295.544C455.758 295.928 455.608 296.295 455.34 296.569C455.073 296.843 454.708 297.001 454.325 297.009H416.373C415.99 297.001 415.626 296.843 415.358 296.569C415.09 296.295 414.94 295.928 414.94 295.544V223.442C414.94 203.749 404.221 192.47 387.426 192.47H386.149C378.778 192.862 371.843 196.08 366.783 201.453C361.723 206.826 358.927 213.943 358.978 221.323V295.544C358.978 295.928 358.828 296.295 358.56 296.569C358.292 296.843 357.928 297.001 357.545 297.009H319.655C319.464 297.009 319.275 296.971 319.099 296.897C318.923 296.823 318.764 296.715 318.63 296.578C318.497 296.442 318.392 296.28 318.322 296.103C318.252 295.925 318.218 295.735 318.222 295.544V156.263C318.218 156.072 318.252 155.882 318.322 155.705C318.392 155.527 318.497 155.366 318.63 155.229C318.764 155.093 318.923 154.984 319.099 154.91C319.275 154.836 319.464 154.798 319.655 154.798H357.607C357.99 154.807 358.354 154.964 358.622 155.238C358.89 155.512 359.04 155.88 359.04 156.263V171.001C359.055 171.035 359.079 171.063 359.109 171.083C359.14 171.103 359.175 171.113 359.212 171.113C359.225 171.113 359.239 171.112 359.252 171.109C359.274 171.104 359.295 171.095 359.314 171.083C359.345 171.063 359.368 171.035 359.383 171.001C363.76 164.929 369.546 160.009 376.243 156.666C382.94 153.323 390.349 151.655 397.833 151.807C433.76 151.901 455.758 176.516 455.758 216.494Z"
            fill="#A33662"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M617.599 235.594H516.238C516.226 235.594 516.214 235.595 516.202 235.597C516.187 235.6 516.173 235.605 516.159 235.611C516.134 235.622 516.112 235.639 516.094 235.66L516.083 235.674C516.071 235.691 516.061 235.711 516.056 235.731C516.053 235.741 516.051 235.751 516.05 235.761C516.048 235.778 516.049 235.795 516.051 235.812C519.884 255.1 533.158 265.133 552.508 265.133C564.629 265.133 577.435 259.898 582.732 251.891C582.932 251.57 583.25 251.34 583.617 251.253C583.985 251.165 584.372 251.227 584.695 251.423L614.141 269.34C614.46 269.54 614.691 269.855 614.784 270.221C614.877 270.587 614.825 270.974 614.639 271.303C603.858 289.375 580.364 299.907 551.635 299.907C532.08 300.188 513.208 292.717 499.145 279.126C485.082 265.535 476.97 246.929 476.583 227.376C476.196 207.822 483.565 188.911 497.08 174.774C510.594 160.637 529.155 152.425 548.706 151.932C557.853 151.51 566.991 152.951 575.564 156.167C584.137 159.384 591.968 164.309 598.579 170.644C605.191 176.979 610.447 184.592 614.027 193.02C617.607 201.448 619.437 210.515 619.406 219.672C619.428 224.792 619.063 229.906 618.316 234.971C618.3 235.147 618.217 235.311 618.083 235.427C617.949 235.543 617.776 235.603 617.599 235.594ZM578.339 209.545C578.352 209.545 578.364 209.544 578.377 209.542C578.391 209.539 578.405 209.535 578.418 209.529C578.443 209.517 578.465 209.501 578.483 209.48C578.501 209.459 578.514 209.435 578.521 209.408C578.524 209.398 578.526 209.388 578.527 209.377C578.529 209.361 578.528 209.344 578.526 209.327C576.687 195.243 563.725 184.929 548.737 184.929C531.725 184.929 520.476 194.09 516.643 209.296C516.633 209.325 516.63 209.356 516.635 209.387C516.639 209.417 516.652 209.446 516.67 209.471C516.678 209.482 516.688 209.492 516.698 209.5L516.71 209.51C516.72 209.517 516.73 209.523 516.741 209.528C516.769 209.541 516.8 209.547 516.83 209.545H578.339Z"
            fill="#A33662"
          />
          <path
            d="M733.169 155.048L730.956 191.816C730.947 191.924 730.914 192.028 730.86 192.121C730.805 192.214 730.729 192.294 730.64 192.354C730.55 192.414 730.447 192.452 730.34 192.467C730.233 192.482 730.124 192.473 730.022 192.439C724.411 190.582 718.544 189.615 712.635 189.573C709.937 189.624 707.265 190.108 704.72 191.006C697.909 193.414 692.022 197.894 687.887 203.817C683.752 209.741 681.575 216.811 681.662 224.035V295.701C681.662 296.089 681.508 296.462 681.234 296.736C680.959 297.011 680.586 297.165 680.198 297.165H642.277C641.889 297.165 641.516 297.011 641.242 296.736C640.967 296.462 640.813 296.089 640.813 295.701V156.263C640.813 155.875 640.967 155.503 641.242 155.228C641.516 154.953 641.889 154.799 642.277 154.799H680.198C680.586 154.799 680.959 154.953 681.234 155.228C681.508 155.503 681.662 155.875 681.662 156.263V170.971C681.662 171.012 681.679 171.052 681.708 171.081C681.737 171.11 681.777 171.126 681.818 171.126C681.84 171.126 681.861 171.122 681.88 171.114C681.898 171.106 681.914 171.095 681.928 171.081C681.958 171.052 681.974 171.012 681.974 170.971C685.68 165.305 690.689 160.609 696.581 157.275C702.474 153.941 709.079 152.066 715.844 151.808C721.562 151.617 727.269 152.44 732.701 154.238C732.867 154.289 733.008 154.401 733.095 154.551C733.181 154.701 733.208 154.879 733.169 155.048Z"
            fill="#A33662"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M884.104 156.263V295.544C884.104 295.933 883.949 296.305 883.675 296.58C883.4 296.854 883.028 297.009 882.639 297.009H844.687C844.304 297.001 843.94 296.843 843.672 296.569C843.404 296.295 843.254 295.927 843.254 295.544V280.837C843.254 280.796 843.238 280.756 843.208 280.727C843.179 280.698 843.14 280.681 843.098 280.681C843.057 280.681 843.017 280.698 842.988 280.727C842.959 280.756 842.943 280.796 842.943 280.837C835.776 292.117 818.95 300 801.251 300C764.203 300 734.945 266.971 734.945 225.841C734.945 184.711 764.203 151.994 801.251 151.994C818.669 151.994 835.776 159.877 842.943 171.157C842.943 171.17 842.944 171.184 842.948 171.196C842.955 171.223 842.968 171.247 842.988 171.267C843.017 171.296 843.057 171.313 843.098 171.313C843.12 171.313 843.141 171.308 843.161 171.3C843.178 171.292 843.195 171.281 843.208 171.267C843.238 171.238 843.254 171.198 843.254 171.157V156.263C843.254 155.88 843.404 155.512 843.672 155.238C843.94 154.964 844.304 154.806 844.687 154.798H882.639C883.028 154.798 883.4 154.952 883.675 155.227C883.949 155.502 884.104 155.874 884.104 156.263ZM843.254 243.197V208.922C843.286 208.789 843.286 208.65 843.254 208.517C839.742 202.963 834.878 198.392 829.118 195.232C823.358 192.071 816.889 190.424 810.319 190.444C800.947 190.444 791.96 194.167 785.333 200.794C778.707 207.42 774.984 216.408 774.984 225.779C774.984 235.15 778.707 244.138 785.333 250.764C791.96 257.391 800.947 261.113 810.319 261.113C816.828 261.187 823.252 259.625 829.001 256.571C834.75 253.516 839.64 249.068 843.223 243.633C843.286 243.496 843.297 243.341 843.254 243.197Z"
            fill="#A33662"
          />
        </g>
      </g>
    </svg>
  );
}
