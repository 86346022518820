import React from 'react';

export default function SoundIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
    >
      <path
        className={props.className}
        fillRule="evenodd"
        d="M4.937 1.231c.44 0 .871.171 1.194.494L18.504 14.1c.484.483.628 1.209.366 1.84-.26.63-.875 1.041-1.559 1.041H9.574l-1.756 1.756c-.33.33-.761.494-1.193.494-.432 0-.863-.165-1.193-.494l-3.938-3.938c-.659-.66-.659-1.728 0-2.386l1.755-1.756.001-7.738c0-.683.411-1.299 1.042-1.56.209-.086.428-.128.645-.128zm-.88 10.204l-.012.018-1.755 1.755c-.144.143-.165.311-.165.398 0 .087.021.254.165.398l3.937 3.937c.144.143.311.165.398.165.087 0 .254-.022.398-.165l1.755-1.755c.005-.006.013-.007.018-.011zm.318-7.554v6.776c0 .086-.031.167-.05.25l4.997 4.999c.083-.019.164-.05.252-.05h6.775L4.375 3.881zm.562-1.525c-.074 0-.146.014-.215.043-.21.087-.347.291-.347.52v.167l12.77 12.77h.166c.229 0 .433-.136.52-.347.088-.21.04-.452-.122-.613L5.335 2.52c-.106-.105-.247-.164-.398-.164zm6.188-1.125l.014.003c4.34.008 7.857 3.526 7.86 7.868l.001.004c0 .311-.251.563-.563.563-.31 0-.56-.251-.562-.56-.001-3.723-3.03-6.75-6.751-6.75-.31-.004-.562-.256-.562-.565 0-.312.252-.563.563-.563zm0 2.25c.006 0 .01.002.014.003 3.098.008 5.607 2.519 5.61 5.618.001.16-.126.285-.28.285-.155 0-.28-.125-.282-.28 0-2.79-2.271-5.062-5.063-5.061-.155-.003-.28-.129-.28-.284 0-.155.125-.28.281-.28z"
      />
    </svg>
  );
}
