import React from 'react';
import { SearchTool, ReadingAidTool, ServiceInfoTool } from './tools';

const HeaderTools = (props) => {
  const { ServiceInfo, ReadingAid, Search } = props.SiteHeader;

  return (
    <div className="header-tools">
      <ul className="header-tools__list">
        {ServiceInfo.Enabled && <ServiceInfoTool {...props} />}
        {ReadingAid.Enabled && <ReadingAidTool {...props} />}
        {Search.Enabled && <SearchTool {...props} />}
      </ul>
    </div>
  );
};

export default HeaderTools;
