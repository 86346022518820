import React from 'react';
import Footer from '../../platformui/Footer';

export default (props) => (
  <Footer
    {...props}
    siteFooterLinkListModifiers={{
      'c-linklist--no-icons': true,
      'c-linklist--inline': true,
    }}
    key="footer"
    {...props}
    ShowLogo={false}
    Modifiers={{ 'footer-linklist': true }}
  />
);
