import React from 'react';

export default function displayIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1565_3084)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1045 17C14.1421 17 16.6045 14.5376 16.6045 11.5C16.6045 8.46243 14.1421 6 11.1045 6C8.06692 6 5.60449 8.46243 5.60449 11.5C5.60449 14.5376 8.06692 17 11.1045 17ZM11.1045 13.5403C12.2313 13.5403 13.1448 12.6268 13.1448 11.5C13.1448 10.3732 12.2313 9.45968 11.1045 9.45968C9.97765 9.45968 9.06417 10.3732 9.06417 11.5C9.06417 12.6268 9.97765 13.5403 11.1045 13.5403Z"
          fill="#E3ABC1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.875 13.5C22.5674 13.5 24.75 11.3174 24.75 8.625C24.75 5.93261 22.5674 3.75 19.875 3.75C17.1826 3.75 15 5.93261 15 8.625C15 11.3174 17.1826 13.5 19.875 13.5ZM19.875 10.4335C20.8738 10.4335 21.6835 9.6238 21.6835 8.625C21.6835 7.6262 20.8738 6.81653 19.875 6.81653C18.8762 6.81653 18.0665 7.6262 18.0665 8.625C18.0665 9.6238 18.8762 10.4335 19.875 10.4335Z"
          fill="#E3ABC1"
        />
        <rect
          x="0.75"
          y="0.75"
          width="28.5"
          height="19.5"
          rx="2"
          stroke="#A33662"
          strokeWidth="2"
        />
        <path
          d="M7.84091 29.25H22.1591"
          stroke="#A33662"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13.5 28.875V21.375"
          stroke="#A33662"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <path
          d="M16.3501 28.875V21.375"
          stroke="#A33662"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_1565_3084">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
