import React from 'react';
import classNames from 'classnames';
import Header from '../../platformui/Header';
import ReadingAidPanel from '../../platformui/ReadingAid/reading-aid-panel';
import Spinner from '../../platformui/Spinner';
import HeaderTools from '../header-tools';
import Tool from './tool';
//import { AuthTool } from '../header-tools/tools';
import HeaderContainer from './header-container';
import GlobalSearch from '../globalsearch';

/* const MobileHeaderTools = (props) => {
  return (
    <div className="c-metanav">
      <ul className="c-metanav__list">
        <AuthTool {...props} />
      </ul>
    </div>
  );
};
 */

const HeaderInera = (props) => {
  const ReadingAid = props.SiteHeader.ReadingAid;
  const ReadingAidProps = {
    ReadingAidText: ReadingAid.Text,
    ReadingAidLoaded: ReadingAid.LoadedText,
    ReadingAidButtonText: ReadingAid.ButtonText,
  };

  return (
    <>
      <ReadingAidPanel
        modifiers={{ 'header-panel': true }}
        {...props}
        isVisible={props.activeTool === 'reading-aid'}
        Spinner={Spinner}
        ReadingAid={ReadingAidProps}
      />
      <GlobalSearch
        modifiers={{ 'header-panel': true }}
        {...props}
        isVisible={props.activeTool === 'search'}
        Spinner={Spinner}
        id="search"
        isMobile={false}
      />
      <GlobalSearch isMobile={true} {...props} id="search--mobile" />

      <div
        className={classNames({
          header: true,
          'header--panel-is-open': props.activeTool !== '',
          'header--search-is-open': props.activeTool === 'search',
          'header--reading-aid-is-open': props.activeTool === 'reading-aid',
        })}
      >
        <Header
          {...props}
          noTopNavRow={true}
          alertListFullWidth
          chevronMobile="chevron--mobile"
          Tools={Tool}
          ToolsBottom={HeaderTools}
          isWideNav={true}
          /* 
        renderAfterMobileMenu={(visible) =>
          visible && <MobileHeaderTools {...props} />
        }
         */
        />
      </div>
    </>
  );
};

export default HeaderContainer(HeaderInera);
